
























































































































































































































































































































































































































































































































































































import EventService from "@/services/EventService";
import LocationService from "@/services/LocationService";
import AuthService from "../services/AuthService";
import moment from "moment";
import "moment/locale/de";
import { Component, Vue, Watch } from "vue-property-decorator";
import router from "../router/index";

@Component
export default class Event extends Vue {
  private events: any = {};
  private locations: any[] = [];
  private location: any = "";
  private searchtext: string = "";
  private currentPage: number = 2;
  private pagesize: number = 10;
  private sortBy = "location_name";
  private sortDesc = false;
  private sortDirection = "asc";
  private selectedRow: any = null;
  private deleteEvent: any = null;
  private canDelete: boolean = false;
  private services: any[] = [];

  selectLocation(location: any) {
    this.selectedRow.seats = location.seats;
  }

  selectDate(event: any) {
    event.leadtime = new Date(event.startDate).getDay() + 1;

    if (event.startTime < "12:00") {
      event.leadtime = event.leadtime - 1;
    }

    if (event.leadtime === 1 || event.leadtime === 7) {
      event.leadtime = 6;
    }
  }

  registrationStart(startDate: Date, opentime: number) {
    return this.date(
      new Date(new Date(startDate).getTime() - opentime * 24 * 60 * 60 * 1000)
    );
  }

  registrationEnd(startDate: Date, leadtime: number) {
    const lt = new Date(startDate).getDay() + 1;
    let count;

    if (Number(leadtime) > lt) {
      count = lt - Number(leadtime) + 7;
    } else {
      count = lt - Number(leadtime);
    }

    return this.date(
      new Date(new Date(startDate).getTime() - count * 24 * 60 * 60 * 1000)
    );
  }

  valid(item: any) {
    return (
      this.numberstate(item.leadtime, 0, 7) &&
      this.numberstate(item.opentime, 1, 200) &&
      this.numberstate(item.seats, 1, 1000) &&
      this.state(item.name) &&
      this.timestate(item.startTime)
    );
  }

  prepareDelete(event: any) {
    EventService.verify(event.uuid).then((response) => {
      this.deleteEvent = event;
      this.canDelete = response.data.canDelete;
    });
  }

  expandAdditionalInfo(row: any) {
    if (this.selectedRow) {
      this.selectedRow._showDetails = false;
    }
    row._showDetails = !row._showDetails;
    this.selectedRow = row;
  }

  add() {
    const today: Date = new Date();
    let leadtime = today.getDay() + 1;

    while (leadtime == 1 || leadtime == 7) {
      if (leadtime == 1) {
        leadtime = 7;
      } else {
        leadtime--;
      }
    }

    this.selectedRow = {
      location: {},
      frequency: "NO",
      startDate: today,
      startTime: "00:00",
      seats: 1,
      opentime: 14,
      leadtime: leadtime,
      registrationMode: "NONE",
      _showDetails: true,
    };
    this.events.content.unshift(this.selectedRow);
  }

  selectSearchLocation(location: any) {
    this.handlePageChange();
  }

  sortChanged(data: any) {
    this.sortBy = data.sortBy;
    this.sortDesc = data.sortDesc;
    this.sortDirection = data.sortDesc ? "desc" : "asc";
    this.handlePageChange();
  }

  handlePageSizeChange() {
    this.currentPage = 1;
    this.reload();
  }

  @Watch("currentPage")
  handlePageChange() {
    this.reload();
  }

  @Watch("searchtext")
  search() {
    this.reload();
  }

  save(event: any) {
    if (event.uuid) {
      EventService.update(event.uuid, event).then((response) => this.reload());
    } else {
      EventService.create(event).then((response) => this.reload());
    }
  }

  remove(event: any) {
    if (this.canDelete) {
      EventService.delete(event.uuid)
        .then((response) => this.reload())
        .catch((err) => {});
    }
  }

  isLoggedIn() {
    return AuthService.isLoggedIn();
  }

  date(date: any) {
    return moment(date).format("dddd, DD.MM.YYYY");
  }

  date2(date: any) {
    return moment(date).format("YYYY-MM-DD");
  }

  state(value: any) {
    if (!value) {
      return false;
    }

    return value.length > 0;
  }

  numberstate(value: number, min: number, max: number) {
    if (!value) {
      return false;
    }

    return value >= min && value <= max;
  }

  timestate(value: any, allowEmpty: boolean = false) {
    if (!value) {
      return allowEmpty ? null : false;
    }

    let match = value.match(/(\d{1,2}):(\d{1,2})/);

    if (!match) {
      return false;
    }

    let hour = Number(match[1]);
    let minute = Number(match[2]);

    return hour >= 0 && hour < 24 && minute >= 0 && minute < 60;
  }

  reload() {
    EventService.findByTitle(
      this.searchtext,
      this.currentPage - 1,
      this.pagesize,
      `${this.sortBy},${this.sortDirection}`,
      { location: this.location.uuid }
    ).then((response) => {
      this.events = response.data;
      this.selectedRow = null;
    });
  }

  mounted() {
    moment.locale("de");
    moment.weekdays(true);

    AuthService.services().then(
      (response) =>
        (this.services = response.data.map(
          (s: string) => <any>{ value: s, text: s }
        ))
    );

    this.reload();
    LocationService.getAll().then((response) =>
      (this.locations = response.data.content.map(
        (l: any) => <any>{ text: l.name, value: l }
      )).sort((a: any, b: any) => (a.text > b.text && 1) || -1)
    );
  }
}
